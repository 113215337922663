<template>
  <div :class="containerClass">
    <page-header
      title="Manage Branding"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-16pt"
      info-text="Configure basic and enrollment portal setting."
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center bg-white" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.logo">...</span>
            <b-img :src="school.logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="school.logo_url" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isUploading.logo"
            :isRequired="!school.logo_url"
            :placeholder="$t('schoolMsgs.logoDesc')"
          />
        </b-media>
      </b-form-group>
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>

        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.smallLogo">...</span>
            <b-img
              :src="school.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="school.small_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo', 'small_logo_url')"
            :isUploading="isUploading.smallLogo"
            :isRequired="!school.small_logo_url"
            placeholder="Select School's Small Logo Picture"
          />
        </b-media>
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
        <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="school.name" required />
      </b-form-group>

      <b-form-group label="Contact email" label-for="email" label-class="form-label">
        <b-form-input id="email" placeholder="Enter school's contact email" v-model="school.email" type="email" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="school.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <b-form-group label="Alternative Phone" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="school.alternativePhone"
          v-mask="'(###) ### - ####'"
        />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
        <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="school.websiteUrl" />
      </b-form-group>

      <b-form-group label="Time zone" label-for="time_zone" label-class="form-label">
        <v-select
          id="time_zone"
          class="form-control v-select-custom"
          label="text"
          v-model="school.time_zone"
          :reduce="country => country"
          placeholder="Enter Time Zone"
          :options="allTimeZones"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school.time_zone" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="school.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="school.city" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="school.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="school.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="school.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <b-form-group :label="$t('schoolMsgs.bio')" label-for="about" label-class="form-label">
        <b-form-textarea
          id="about"
          :placeholder="`${$t('schoolMsgs.bioPlaceholder')}`"
          :rows="5"
          v-model="school.bio"
        />
      </b-form-group>
      <page-separator title="Payment Configuration" class="mb-3" />
      <b-form-group>
        <b-form-checkbox id="checkbox-inapp" v-model="inappPaymentEnable" name="checkbox-inapp" switch>
          Is In-app payment enabled?
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Allow students to pay their pending payment plans via transition.`"
            >info</i
          >
        </b-form-checkbox>
      </b-form-group>
      <page-separator title="Program's Info" class="mb-3" />

      <b-form-group label="Refer 'Tuition Fee' as" label-for="tuition_fee_name" label-class="form-label">
        <b-form-input id="tuition_fee_name" placeholder="Tuition Fee" v-model="school.tuitionFee" />
      </b-form-group>

      <b-form-group label="Refer 'Application Fee' as" label-for="application_fee_name" label-class="form-label">
        <b-form-input id="application_fee_name" placeholder="Application Fee" v-model="school.appFee" />
      </b-form-group>
      <b-form-group label="Refer 'Total Tuition' as" label-for="ttl_tuition_name" label-class="form-label">
        <b-form-input id="ttl_tuition_name" placeholder="Total Tuition" v-model="school.ttl_tuition_name" />
      </b-form-group>
      <page-separator title="Enrollment Portal" class="mb-3" />

      <b-form-group label="URL" label-for="subdomain" label-class="form-label">
        <b-alert variant="soft-light px-12pt" show>
          <div class="d-flex align-items-center justify-content-between">
            <span class="text-black-100"
              ><strong>{{ school.subdomain }}</strong></span
            >

            <span v-if="isSubdomainCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
            <a href="#" @click.prevent="copySubdomain" v-else v-b-popover.hover.top="'Copy URL'">
              <md-icon>content_copy</md-icon>
            </a>
          </div>
        </b-alert>
      </b-form-group>
      <b-form-group label="Title" label-for="pp_home_title" label-class="form-label">
        <b-form-input id="pp_home_title" v-model="school.pp_home_title" maxlength="100" placeholder="Enter title" />
      </b-form-group>

      <b-form-group label="Description" label-for="pp_home_description" label-class="form-label">
        <b-form-input
          id="pp_home_description"
          v-model="school.pp_home_description"
          maxlength="250"
          placeholder="Enter description"
        />
      </b-form-group>
      <b-form-group>
        <label for="ssnFormat" class="form-label">
          SSN Format Configuration
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Allow students to register using either the last four digits of their SSN or the full SSN.`
            "
            >info</i
          >
        </label>
        <v-select
          id="ssn-format"
          class="form-control v-select-custom"
          label="text"
          v-model="is4DigitSSN"
          :reduce="format => format.value"
          placeholder="Select SSN Format"
          :options="ssnFormats"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Link Text" label-for="pp_cta_link_text" label-class="form-label">
        <b-form-input id="pp_cta_link_text" v-model="school.pp_cta_link_text" placeholder="Enter Link Text" />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox id="checkbox-3" v-model="showBannerStatus" name="checkbox-3" switch>
          Display description on public page
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <label class="form-label">
          Registration Page Description
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Use {{application_fee}} and {{tuition_fee}} bookmarks for showing relevant program fees in the description.`
            "
            >info</i
          >
        </label>
        <b-form-textarea
          id="pp_registration_desc"
          :placeholder="
            `Note: A non-refundable application fee of {{application_fee }} is required for online registration submissions. Before registering, please make sure to contact us to make sure all of your questions are answered.`
          "
          v-model="school.pp_registration_desc"
          :rows="3"
        />
      </b-form-group>
      <b-form-group label="Product Page Description" label-for="pp_products_desc" label-class="form-label">
        <b-form-textarea
          id="pp_products_desc"
          placeholder="View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will be received on the date of class orientation."
          v-model="school.pp_products_desc"
          :rows="3"
        />
      </b-form-group>
      <b-form-group label="Important Visitor Notice Title" label-for="visitor_notice_title" label-class="form-label">
        <b-form-input
          id="visitor_notice_title"
          placeholder="Enter Visitor Notice Title"
          v-model="school.visitor_notice_title"
        />
      </b-form-group>
      <b-form-group
        label="Important Visitor Notice Description"
        label-for="visitor_notice_desc"
        label-class="form-label"
      >
        <b-form-textarea
          id="visitor_notice_desc"
          placeholder="Enter Visitor Notice Description"
          v-model="school.visitor_notice_desc"
          :rows="5"
        />
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage', 'profile_bg_img_url')"
            :isUploading="isUploading.profileBgImage"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.profileBgImageDesc')"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>
      <b-form-group label="Registration Terms and Conditions" label-for="registration_terms" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="school.registration_terms"
          placeholder="Enter student registration terms and conditions."
        />
      </b-form-group>

      <b-form-group label="Cancellation Terms" label-for="cancellation_terms" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="school.cancellation_terms"
          placeholder="Enter student cancellation terms."
        />
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $t('update') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import Editor from '@tinymce/tinymce-vue';
import PageHeader from '../../../components/Ui/PageHeader.vue';
import Page from '../../../components/Page.vue';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import moment from 'moment-timezone';
import { DEFAULT_FAVICON, SCHOOL_PAYMENT_PLANS } from '../../../common/constants';
import { parseFileNameFromUrl } from '../../../common/utils';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    VSwatches,
    Editor,
    vSelect,
    PageSeparator,
    ImageUploader,
  },
  extends: Page,

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96'],
      ],
      title: 'School Profile',
      owner: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      school: {
        name: '',
        email: '',
        phone: '',
        alternativePhone: '',
        websiteUrl: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        country: 'United States',
        logo_url: null,
        small_logo_url: null,
        profile_bg_img_url: null,
        chatBotImageUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        bio: '',
        tuitionFee: 'Tuition Fee',
        appFee: 'Application Fee',
        ttl_tuition_name: 'Total Tuition',
        pp_home_title: 'Welcome!',
        pp_home_description:
          'Our school offers a variety of programs and classes for aspiring healthcare professionals.',
        pp_registration_desc: null,
        pp_products_desc: null,
        visitor_notice_title: null,
        visitor_notice_desc: null,
        registration_terms: '',
        cancellation_terms: '',
        time_zone: 'America/New_York',
        pp_cta_link_text: 'Enroll Now',
        chatBot: {
          name: '',
          welcomeMsg: '',
        },
      },
      allPrograms: [],
      allTimeZones: [],
      inappPaymentEnable: false,
      is4DigitSSN: false,
      countryOptions: [],
      stateOptions: [],
      errorStates: {
        password: null,
        ownerEmail: null,
      },
      passErrorCode: null,
      isPassCopied: false,
      isSubdomainCopied: false,
      isLoading: false,
      isProfileBgImgUploading: false,

      isUploading: { logo: false, smallLogo: false, profileBgImage: false, chatBotImage: null },
      selectedUploadFile: { logo: null, smallLogo: null, profileBgImage: null, chatBotImage: null },
      uploadPercent: { logo: 0, smallLogo: 0, profileBgImage: 0, chatBotImage: null },
      uploadCancelTokenSource: { logo: null, smallLogo: null, profileBgImage: null, chatBotImage: null },
      areProgramsLoading: false,
      showBannerStatus: true,
      SCHOOL_PAYMENT_PLANS,
      ssnFormats: [
        
        {
          text: 'Last 4-digits format',
          value: true,
        },
        {
          text: 'Complete 9-digits format',
          value: false,
        },
        
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool', 'getSchoolPaymentPlan']),
    breadcrumb() {
      return [
        {
          text: 'School Profile',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    },
  },

  methods: {
    ...mapActions('school', ['getSchool', 'updateMySchoolProfile', 'getSchoolImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    get,
    parseFileNameFromUrl,
    setImage(file, image, profileUrlField = 'logo_url') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, profileUrlField);
    },
    async copySubdomain() {
      try {
        await navigator.clipboard.writeText(this.school.subdomain);
        this.isSubdomainCopied = true;
        setTimeout(() => (this.isSubdomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        await this.updateMySchoolProfile({
          name: this.school.name,
          contact_email: this.school.email,
          phone: this.school.phone,
          alternate_phone: this.school.alternativePhone,
          website_url: this.school.websiteUrl,
          zipcode: this.school.zipcode,
          address: this.school.address,
          city: this.school.city,
          state: this.school.state,
          country: this.school.country,
          logo_url: this.school.logo_url,
          small_logo_url: this.school.small_logo_url,
          profile_bg_img_url: this.school.profile_bg_img_url,
          primary_color: this.school.primaryColor,
          secondary_color: this.school.secondaryColor,
          dark_color: this.school.darkColor,
          bio: this.school.bio,
          tuition_fee_name: this.school.tuitionFee,
          app_fee_name: this.school.appFee,
          ttl_tuition_name: this.school.ttl_tuition_name,
          pp_home_title: get(this.school, 'pp_home_title.length') ? this.school.pp_home_title : null,
          pp_home_description: get(this.school, 'pp_home_description.length') ? this.school.pp_home_description : null,
          pp_registration_desc: this.school.pp_registration_desc,
          pp_products_desc: this.school.pp_products_desc,
          visitor_notice_title: this.school.visitor_notice_title,
          visitor_notice_desc: this.school.visitor_notice_desc,
          time_zone: this.school.time_zone,
          pp_cta_link_text: get(this.school, 'pp_cta_link_text.length') ? this.school.pp_cta_link_text : null,
          show_banner_status: this.showBannerStatus,
          registration_terms: this.school.registration_terms,
          cancellation_terms: this.school.cancellation_terms,
          is_four_digit_ssn: this.is4DigitSSN,
          inapp_pymnt_enable: this.inappPaymentEnable,
        });
        this.makeToast({ variant: 'success', msg: 'School Profile Updated' });

        // Update app's appearance.
        document.documentElement.style.setProperty('--primary-color', this.school.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', this.school.secondaryColor);
        document.documentElement.style.setProperty('--dark-color', this.school.darkColor);
        const favicon = document.getElementById('favicon');
        favicon.href = get(this.school, 'small_logo_url') || DEFAULT_FAVICON;
        this.updateNavbarBrandDetails();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async uploadFile(fileType, profileUrlField = 'logo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (profileUrlField === 'chatBotImageUrl') {
          this.school.chatBotImageUrl = urlResp.upload_url.split('?')[0];
        } else {
          this.school[profileUrlField] = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    async fetchSchoolProfile() {
      this.isLoading = true;
      try {
        const resp = (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
        this.school.name = resp.name;
        this.school.email = resp.contact_email;
        this.school.phone = resp.phone;
        this.school.alternativePhone = resp.alternate_phone;
        this.school.websiteUrl = resp.website_url;
        this.school.zipcode = resp.zipcode;
        this.school.address = resp.address;
        this.school.city = resp.city;
        this.school.state = resp.state;
        this.school.country = resp.country;
        this.school.logo_url = resp.logo_url;
        this.school.small_logo_url = resp.small_logo_url;
        this.school.subdomain = resp.subdomain.subdomain_url;
        this.school.profile_bg_img_url = resp.profile_bg_img_url;
        this.school.primaryColor = resp.primary_color;
        this.school.secondaryColor = resp.secondary_color;
        this.school.darkColor = resp.dark_color;

        this.school.tuitionFee = resp.tuition_fee_name || 'Tuition Fee';
        this.school.appFee = resp.app_fee_name || 'Application Fee';
        this.school.ttl_tuition_name = resp.ttl_tuition_name || 'Total Tuition';
        this.school.bio = resp.bio;
        this.school.pp_home_title = resp.pp_home_title;
        this.school.pp_home_description = resp.pp_home_description;
        this.school.pp_cta_link_text = resp.pp_cta_link_text;
        this.school.pp_registration_desc = resp.pp_registration_desc;
        this.school.pp_products_desc = resp.pp_products_desc;
        this.school.visitor_notice_title = resp.visitor_notice_title;
        this.school.visitor_notice_desc = resp.visitor_notice_desc;
        this.school.time_zone = resp.time_zone;

        this.showBannerStatus = resp.show_banner_status;
        this.school.registration_terms = resp.registration_terms;
        this.school.cancellation_terms = resp.cancellation_terms;
        this.is4DigitSSN = resp.is_four_digit_ssn;
        this.inappPaymentEnable = resp.inapp_pymnt_enable;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name,
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name,
        }))
      );
    },
  },

  async mounted() {
    this.allTimeZones = moment.tz.names();
    this.fillInOptions();
    this.fetchSchoolProfile();
  },
};
</script>
